import type { AlertColor } from 'shared/ui/alert/Alert';

import { type CurrencyCode } from 'currencies';

import type { CurrencyViewMode } from './currency-toggle-group/CurrencyToggleGroup';

/* eslint-disable import/exports-last -- We like to keep the private types next to the public types they help define */
export type LabelValueOption<L = string, V = string> = {
  label: L;
  value: V;
  disabled?: boolean;
};

export type TraceId<T extends string = string> = string & {
  // https://spin.atomicobject.com/2018/01/15/typescript-flexible-nominal-typing/
  _modelName?: T;
};

export type TraceIdField = { trace_id: TraceId };

type AuditFields = {
  // TODO: These fields should be required, but we didn't bother putting them in any of our tests
  created_at?: string;
  updated_at?: string;
};

export type BaseResponseFields = AuditFields & TraceIdField;
export type BaseRequestFields = { trace_id?: TraceId };

export type FeatureFlagResponse = BaseResponseFields & {
  name: string;
  enabled: boolean;
};
type AdministrativeOrProcedureType = 'ADMIN' | 'INV';

export type AdministrativeOrProcedureCostResponse = BaseResponseFields & {
  cost_category: TraceId;
  site_or_lab_contract: TraceId;
  cost: number;
};

export type AdministrativeOrProcedureCostUpsertRequest = {
  category_type: AdministrativeOrProcedureType;
  category?: TraceId;
  category_name?: string;
  site_or_lab_contract: TraceId;
  cost: number;
  trial: TraceId;
};

export type VisitCostResponse = BaseResponseFields & {
  patient_assessment: TraceId;
  site_or_lab_contract: TraceId;
  cost: number;
};

export type VisitCostRequest = {
  site_or_lab_contract: TraceId;
  patient_assessment: TraceId;
  cost: number;
};

export type UnmappedFileResponse = BaseResponseFields & {
  url: string;
  file: string;
  uploaded_by: {
    first_name: string;
    last_name: string;
  } | null;
  status: string;
  rows_count: number;
  columns_count: number;
  error?: string;
  message?: string;
};

export enum UnmappedFileEntityType {
  ScreenFailSnapshot = 'SFS',
  EdcSnapshot = 'EDCS',
  InvoiceSnapshot = 'INV',
  UnscheduledVisitsEdcSnapshot = 'UVS',
  ProceduresEdcSnapshot = 'PES',
  SiteSnapshot = 'SS',
  ContractBudgetSnapshot = 'CBS',
  PurchaseOrderSnapshot = 'POS',
  DirectFeesSnapshot = 'DFS',
  PassthroughsSnapshot = 'PAS',
}

export type UploadedFile = {
  csvBlobUrl: string;
  unmappedFileUrl: string;
  traceId: TraceId;
};

export type FlatFileMappingResponse = BaseResponseFields & {
  mapped_columns: [];
  mapping_name: string;
  source_column_count: number;
};

export type FlatFileMappingColumnResponse = BaseResponseFields & {
  mapping: string;
  field_name: string;
  column_position: number;
};

export type CompanyResponse = BaseResponseFields & {
  name: string;
  variance_threshold_amount?: number;
  variance_threshold_percent?: number;
};

export type ProgramResponse = TraceIdField & { name: string };

export type LabRequest = BaseRequestFields & {
  trial?: TraceId;
  name?: string;
  country?: string;
};

export type LabResponse = BaseResponseFields & LabRequest;

export type LabContractRequest = BaseRequestFields & {
  lab?: TraceId;
  contract_container?: TraceId;
};

export type LabContractResponse = BaseResponseFields & { lab: LabResponse };

export enum CostType {
  MANUAL = 'M',
  AVG_COUNTRY_CURRENCY = 'AVGCC',
}

export type SiteContractRequest = BaseRequestFields & {
  site?: TraceId;
  contract_container?: TraceId;
  region?: TraceId | null;
};

export type SiteContractResponse = BaseResponseFields & {
  site: SiteRecordResponse;
  cost_type: CostType;
};

export type TrialRequest = BaseRequestFields & {
  indication?: string;
  study_id?: string;
  status?: string | null;
  phase?: string;
  company: string;
  name?: string;
  is_in_flight?: boolean;
  national_clinical_trial_url?: string;
  trial_balance?: number;
  program?: string;
};

export type TrialResponse = BaseResponseFields & {
  indication: string;
  study_id: string;
  status: string | null;
  phase: string;
  company: string;
  name: string;
  is_in_flight: boolean;
  national_clinical_trial_url?: string;
  trial_balance?: number;
  program: ProgramResponse;
  currency: CurrencyCode;
  preparer: {
    name: string | undefined;
    trace_id: string | null | undefined;
  } | null;
  reviewer: {
    name: string | undefined;
    trace_id: string | null | undefined;
  } | null;
};

export type VendorContactResponse = BaseResponseFields & {
  contract_container: string;
  name: string;
  email: string;
};

export type VendorType = 'CRO' | 'OCC';

export type ContractVersionResponse = BaseResponseFields & {
  contract_container_id: TraceId;
  version: BackendContractVersion;
  execution_date?: string;
  effective_date?: string;
  aip_effective_date?: string;
  voided_date?: string;
  last_used_date?: string;
  vendor_name?: string;
  vendor_type?: string;
  description?: string;
  po_number?: string;
  cnf_threshold_amount?: number;
  cnf_threshold_percentage?: number;
  tax_rate?: number;
  bonus_and_penalties?: string;
  children?: string[];
  version_name?: string;
  gross_contract_value?: number;
  net_contract_value?: number;
  amendment_number?: string;
  status_for_period?: BackendContractStatus;
  status_trace_id_for_period?: TraceId;
  trial_trace_id: TraceId;
  regions?: TraceId[];
};

export type ContractRequestParams = BaseRequestFields & {
  contract_container: TraceId;
  version: BackendContractVersion;
  execution_date?: string;
  effective_date?: string;
  aip_effective_date?: string;
  voided_date?: string;
  last_used_date?: string;
  vendor_name?: string;
  description?: string;
  po_number?: string;
  cnf_threshold_amount?: number;
  cnf_threshold_percentage?: number;
  tax_rate?: number;
  bonus_and_penalties?: string;
  children?: string[];
  version_name?: string;
  gross_contract_value?: number;
  net_contract_value?: number;
  currency?: string;
  amendment_number?: string;
  status_for_period: BackendContractStatus; // not a column in the backend model, but required for create
  period?: TraceId; // not a column in the backend model, but required for create
};

export type ContractStatusRequestParams = BaseRequestFields & {
  status: BackendContractStatus;
};

export type ContractStatusResponse = BaseResponseFields & {
  status: BackendContractStatus;
  period: TraceId;
  contract_version: TraceId;
};

export enum BlaResolutionType {
  CURRENT = 'CUR',
  AIP = 'AIP',
}

export type VendorResponse = BaseResponseFields & {
  name: string;
  company: TraceId;
  vendor_type?: VendorType;
  contract_containers?: ContractContainerResponse[];
};

export type ContractContainerRequest = BaseResponseFields & {
  // ?: does this really want to be BaseRequestFields? Some stuff broke when I tried that...
  trial: TraceId;
  vendor?: TraceId;
  contract_id: string;
  po_numbers?: string[];
  currency: string;
};

export type ContractContainerResponse = BaseResponseFields & {
  trial: TraceId;
  vendor?: VendorResponse;
  contract_id: string;
  currency: CurrencyCode;
  po_numbers: string[];
  contract_versions: string[];
  vendor_type: VendorType;
  vendor_name?: string;
  vendor_contacts?: VendorContactResponse[];
};

export enum GlAccountType {
  ACCRUED = 'ACCRUED',
  CONTRA_AP = 'CONTRA_AP',
  EXPENSE_ACCOUNT = 'EXPENSE_ACCOUNT',
  LONG_TERM_PREPAID = 'LONG_TERM_PREPAID',
  SHORT_TERM_PREPAID = 'SHORT_TERM_PREPAID',
}

export enum GlAccountTypeTranslation {
  ACCRUED = 'Accrued G/L',
  CONTRA_AP = 'Contra AP G/L',
  EXPENSE_ACCOUNT = 'Expense Account',
  LONG_TERM_PREPAID = 'Long Term Prepaid G/L',
  SHORT_TERM_PREPAID = 'Short Term Prepaid G/L',
}

export type GlAccountResponse = BaseResponseFields & {
  company: string;
  account_number: string;
  account_type: GlAccountType;
  description: string;
};

export enum AssumptionGroupName {
  // Timeline
  START_UP = 'SUM',
  CONDUCT = 'COM',
  ENROLLMENT = 'ENM',
  TREATMENT = 'TRM',
  FOLLOWUP = 'FOM',
  CLOSE = 'CLM',
  OVERALL = 'OVM', // THIS IS A "FAKE" ASSUMPTION GROUP AND IS ONLY INFORMATIONAL
  // Sites
  SITES = 'SIT',
  CLINICAL_DURATION = 'SPD',
  SITE_MONTHS = 'SIM',
  // Patient Enrollment
  PATIENTS_SCREENED = 'PSC',
  PATIENTS_ENROLLED = 'PEN',
  PATIENTS_DROPPED_COMPLETED = 'PDC',
  // Patient Months
  PATIENT_THROUGHPUT = 'PTP',
  PATIENT_MONTHS = 'PMO',
  // PATIENT_ONSITE_VISITS = 'POV', // TEMPORARILY REMOVED
  // IMV_PER_PATIENT_MONTH = 'IPM', // TEMPORARILY REMOVED
}

export enum DataType {
  DATE = 'DTE',
  INTEGER = 'INT',
  DECIMAL = 'DEC',
}

export enum DateAssumptionType {
  START = 'START',
  END = 'END',
}

type ParameterResponse = BaseResponseFields & {
  region?: TraceId;
  date_assumption_type?: DateAssumptionType;
  /** @deprecated use date_assumption_type instead. this is here only to support older closed periods */
  date_param_type?: DateAssumptionType;
  value?: string;
};

type ParameterRequest = BaseRequestFields & {
  region?: TraceId | null;
  date_assumption_type?: DateAssumptionType;
  /** @deprecated use date_assumption_type instead. this is here only to support older closed periods */
  date_param_type?: DateAssumptionType;
  value?: string;
};

export type AssumptionGroupResponse = BaseResponseFields & {
  contract_version: TraceId;
  name: AssumptionGroupName;
  data_type: DataType;
  contract_params: ParameterResponse[];
};

export type CurrentUserResponse = BaseResponseFields & {
  is_superuser: boolean;
  permissions: {
    can_edit_company_users_and_permissions?: boolean;
    can_edit_company_settings?: boolean;
    can_edit_company_level_info?: boolean;
    can_delete_company_level_info?: boolean;
    can_signoff_as_preparer?: boolean;
    can_signoff_as_reviewer?: boolean;
    can_manage_trial_roles?: boolean;
    can_edit_trial_info?: boolean;
    can_delete_trial_info?: boolean;
    can_open_period?: boolean;
    can_close_period?: boolean;
    can_reopen_period?: boolean;
    can_add_comments?: boolean;
    can_edit_historic_values?: boolean;
    can_access_forecasting?: boolean;
    can_create_and_edit_forecasting?: boolean;
    can_edit_data?: boolean;
    can_edit_period_closed_values?: boolean;
    can_lock_grids?: boolean;
  } | null;
};

export type EmailVerificationInfo = { auth0UserId: string };

export type UserPermissions = {
  // Note: Please update the file models/users.py for any changes below
  // 1. Company Permissions
  canEditCompanyUsersAndPermissions: boolean;
  canEditCompanySettings: boolean;
  canEditCompanyLevelInfo: boolean;
  canDeleteCompanyLevelInfo: boolean;
  canSignoffAsPreparer: boolean;
  canSignoffAsReviewer: boolean;

  // # 2. Trial Permissions
  canManageTrialRoles: boolean;

  canEditTrialInfo: boolean;
  canDeleteTrialInfo: boolean;

  // # 2.a. Period level
  canOpenPeriod: boolean;
  canClosePeriod: boolean;
  canReopenPeriod: boolean;

  // # 2.b. Comments, forecasting, and historical values
  canAddComments: boolean;
  canEditHistoricValues: boolean;
  canAccessForecasting: boolean;
  canCreateAndEditForecasting: boolean;

  // # 3. Other miscellaneous permissions
  canEditPeriodClosedValues: boolean;
  canLockGrids: boolean;

  // Permissions which are going to be deprecated
  canEditData?: boolean;
};

export type UserOption = DropdownOption<string | undefined> & {
  label: string | null | undefined;
  value: string | null | undefined;
  name?: string;
  picture?: string;
  company_name?: string;
  title?: string;
};

type AssumptionGroupRequest = BaseRequestFields & {
  contract_version: TraceId;
  name: AssumptionGroupName;
  data_type: DataType;
  contract_params: ParameterRequest[];
};

export type AssumptionGroupRequestDisplay = AssumptionGroupRequest & {
  showOverall: boolean;
  showByRegion: boolean;
  editable: boolean;
  required?: boolean;
  calculation: (
    assumptionGroup: AssumptionGroupRequestDisplay,
    allAssumptionGroups: AssumptionGroupRequestDisplay[],
  ) => AssumptionGroupRequestDisplay[];
  showEmptyRowAfter: boolean;
  overallValue: number | undefined;
  contract_params: ParameterRequest[];
};

export type ContractInfo = {
  traceId?: TraceId;
  contractStatus: BackendContractStatus;
  contractVersion: BackendContractVersion;
  versionName?: string;
  description?: string;
  effectiveDate?: string;
  aipEffectiveDate?: string;
  voidedDate?: string;
  poNumber?: string;
  accountInfo: AccountingInfo;
  documents: ContractFile[];
  amendmentNumber?: string;
  periodVersion?: PeriodVersionResponse | null;
};

// TODO: move to Autocomplete.tsx
export type DropdownOption<T> = { value: T; label: string };

export type ContractFileApiResult = BaseResponseFields & {
  file_name: string;
  tag: string;
  file: string;
};

export type SiteFileRequest = BaseRequestFields & {
  site: TraceId;
  file_name: string;
  tag: string;
  file?: File;
  company: string;
  period_version: string;
};

export type SiteFileResponse = BaseResponseFields & {
  site: TraceId;
  file_name: string;
  tag: string;
  file: File;
  company: string;
  period_version: string;
};

export type AccountingInfo = {
  currency?: string;
  useCnfThreshold: boolean;
  cnfThresholdAmount?: number;
  cnfThresholdPercentage?: number;
  costCategoryInfos?: ContractCostCategoryInfoRequestParams[];
  bonusesAndPenalties?: string;
  bottomLineAdjustments: BottomLineAdjustment[];
};

export type BottomLineAdjustmentAmountType = 'ABSOLUTE' | 'PERCENT';
export type BottomLineAdjustmentType =
  | 'DISCOUNT'
  | 'INFLATION'
  | 'REBATE'
  | 'TAX_RATE';

export type BottomLineAdjustment = {
  traceId?: TraceId;
  adjustmentType?: BottomLineAdjustmentType;
  amountType: BottomLineAdjustmentAmountType;
  amount?: number;
  amountPercentage?: number;
  vendorReportedBlaAmount?: number;
};

export type BottomLineAdjustmentRequestParams = {
  contract: string;
  adjustment_type: BottomLineAdjustmentType;
  amount_type: BottomLineAdjustmentAmountType;
  amount?: number | null;
  amount_percentage?: number | null;
};

export type BottomLineAdjustmentResponse = BaseResponseFields &
  BottomLineAdjustmentRequestParams;

export type VendorReportedBottomLineAdjustmentResponse = BaseResponseFields & {
  bottom_line_adjustment: TraceId;
  period_trace_id: TraceId;
  amount: number;
};

export type ContractCostCategoryInfoRequestParams = BaseRequestFields & {
  contract_version?: string;
  category: string;
  gl_account?: string;
  gross_value?: number;
};

export type ContractCostCategoryInfoResponse = BaseResponseFields & {
  contract_version?: string;
  category: string;
  gl_account?: GlAccountResponse;
  gross_value?: string;
  net_value?: string;
};

export type ContractFile = {
  traceId?: TraceId;
  tag: string;
  file?: File;
  file_name?: string;
};

// patient cohorts and visits (aka assessments)

export type PatientCohort = {
  traceId?: TraceId;
  trialId: TraceId;
  name: string;
  pizzaName?: string;
  patientAssessments: PatientAssessment[];
  mappedName?: string;
  isMagicMapped?: boolean;
  orderIndex: number;
};

export type PatientCohortRequest = BaseRequestFields & {
  trial: string;
  name: string;
  pizza_name?: string;
  order_index: number;
};
export type PatientCohortResponse = BaseResponseFields &
  PatientCohortRequest & { patient_assessments: PatientAssessmentResponse[] };

/**
 * aka Visit
 */
export type PatientAssessment = {
  name: string;
  patientCohort: TraceId;
  pizzaName?: string;
  traceId?: TraceId;
  mappedName?: string;
  isMagicMapped?: boolean;
  orderIndex: number;
  isScreen: boolean;
  isEnroll: boolean;
  isScreenfail: boolean;
  timelinePortion: PatientAssessmentTimelinePortionEnum;
  isDroppedCompleted: boolean;
  dayOfProtocol: number | null;
};

/**
 * aka VisitResponse
 */
export type PatientAssessmentResponse = BaseResponseFields & {
  patient_cohort: string;
  name: string;
  pizza_name?: string;
  is_enroll: boolean;
  is_screen: boolean;
  is_screenfail: boolean;
  is_dropped_completed: boolean;
  order_index: number;
  day_of_protocol: number | null;
  timeline_portion: PatientAssessmentTimelinePortionEnum;
};

export enum PatientAssessmentTimelinePortionEnum {
  FOLLOW_UP = 'follow_up',
  OTHER = 'other',
  TREATMENT = 'treatment',
}

/**
 * aka VisitRequestParams
 */
export type PatientAssessmentRequestParams = Omit<
  PatientAssessmentResponse,
  'created_at' | 'trace_id' | 'updated_at'
>;

export type PatientAssessmentEditor = PatientAssessment & {
  isEdited?: boolean;
  patientCohort: TraceId;
  isNew: boolean;
};
export type PatientCohortEditor = PatientCohort & {
  isEdited?: boolean;
  patientAssessments: PatientAssessmentEditor[];
  isNew: boolean;
};

export type RegionResponse = BaseResponseFields & {
  contract_container: {
    trace_id: TraceId;
    vendor: TraceId;
  };
  name: string;
  regiongroups: RegionGroupResponse[];
};

export type RegionAndRegionGroupResponse = TraceIdField & {
  name: string;
  type: 'Global' | 'Region' | 'RegionGroup';
};

export type RegionGroupResponse = BaseResponseFields & {
  name: string;
  contract_container: TraceId;
  regions: string[];
};

type RegionGroupRequest = BaseRequestFields & {
  name: string;
  contract_version?: TraceId;
  regions?: string[];
};

type RegionRequest = BaseRequestFields & {
  name: string;
  contract_version?: TraceId;
  regiongroups?: RegionGroupResponse[];
};

export enum CRUDAction {
  CREATE,
  DELETE,
  UPDATE,
}

export type RegionGroupListItemType = RegionGroupRequest & {
  action?: CRUDAction;
  global: boolean;
  new: boolean;
};

export type RegionListItemType = RegionRequest & {
  action?: CRUDAction;
  new: boolean;
};

export enum AssumptionGroupSectionName {
  TIMELINE = 'Timeline',
  SITES = 'Sites',
  PATIENT_ENROLLMENT = 'Patient Enrollment',
  PATIENT_MONTHS = 'Patient Months (Enrollment * throughput)',
}

// EDC snapshots and records

// If you update this for EDC uploads - as in not SS (Site) and not CBS (Contract Budget),
// please also update EdcVisitType in patient_activity.py
export type FileUploadType =
  | '*'
  | 'CBS'
  | 'EDCS'
  | 'PES'
  | 'SFS'
  | 'SS'
  | 'UVS';

export type EdcSnapshotResponse = BaseResponseFields & {
  comment?: string;
  original_upload: TraceId;
};

export type EdcRecordResponse = BaseResponseFields & {
  snapshot_row?: number;
  visdat?: string;
  subject?: string;
  site_number?: string;
  site_group?: string;
  site_name?: string;
  site_record?: SiteRecordResponse | TraceId;
  savets?: string;
  project?: string;
  study_id?: string;
  cohort?: string;
  visit_name?: string;
};

export type ProceduresEdcRecordResponse = EdcRecordResponse & {
  confirmed_event?: string;
  invoiceable_detail?: string;
  invoiceable_type?: string;
};

export type ProceduresEdcSnapshotResponse = EdcSnapshotResponse & {
  procedure_category: AdministrativeOrProcedureCategoryResponse | null;
  original_upload: UnmappedFileResponse;
};

// Site Listing Snapshots and records

export type SiteListingSnapshotResponse = BaseResponseFields & {
  comment?: string;
  records?: SiteListingRecordResponse[];
};

export type SiteListingRecordResponse = BaseResponseFields & {
  snapshot?: TraceId;
  snapshot_row?: number;
  site_number?: string;
  site_name?: string;
  pi_name?: string;
  site_country?: string;
  site_initiated_date?: string;
  site_recruited_date?: string;
  site_closed_date?: string;
  site_study_id?: string;
  subjects_prescreened?: number;
  subjects_screened?: number;
  subjects_screen_failed?: number;
  subjects_enrolled?: number;
};

export type SiteListingApplyRequest = { snapshot: TraceId; trial: TraceId };

export type SiteRecordRequest = BaseRequestFields & {
  name?: string;
  number: string;
  country: string | undefined;
  initiated_date?: string;
  closed_date?: string;
  recruited_date?: string;
  investigator_name?: string;
  investigator_email?: string;
  version_date?: string;
  site_or_lab?: TraceId;
};
export type SiteRecordResponse = BaseResponseFields &
  SiteRecordRequest & { trial: TraceId };

export type AdministrativeOrProcedureCategoryRequest = BaseRequestFields & {
  trial: TraceId;
  name: string;
  category_type: AdministrativeOrProcedureType;
  mapped_edc_name: string | null;
};
export type AdministrativeOrProcedureCategoryResponse =
  AdministrativeOrProcedureCategoryRequest & BaseResponseFields;

export type SnapshotRecordType = Record<
  string,
  boolean | number | string | null
>;

export const getStringFromSiteRecord = (siteRecord: SiteRecordResponse) =>
  `${siteRecord.name} - ${siteRecord.number}`;

export type InvoiceSnapshotsResponse = BaseResponseFields & {
  company: string;
  comment: string;
  records: InvoiceRecordResponse[];
};

export type InvoiceRecordResponse = BaseResponseFields & {
  snapshot: string;
  snapshot_row: number;
  po_number?: string;
  invoice_amount: number;
  gl_account: string;
  status: string;
  invoice_number: string;
  vendor: string;
  invoice_date?: string;
  invoice_paid_date?: string;
  invoice_paid_amount?: number;
  description?: string;
  project_id?: string;
  currency?: string;
  trial_id?: string;
};

type PoCompletenessRow = {
  po_number: string;
  vendor: string;
  po_amount: number;
  completeness_status: string;
};

export type PoCompletenessResponse = {
  po_numbers: PoCompletenessRow[];
  total_contract_values: Record<string, number | undefined>;
  trial_currency: CurrencyCode;
};

export enum SignoffType {
  PREPARER = 'PREPARER',
  REVIEWER = 'REVIEWER',
}

export type SignoffBy = {
  first_name: string;
  last_name: string;
  trace_id: TraceId;
};
export type SignOffResponse = BaseResponseFields & {
  period: TraceId;
  task: FinancialCloseTaskType | string;
  signoff_type: SignoffType;
  signoff_by: SignoffBy;
};

export type ChecklistSignoff = {
  trace_id: TraceId;
  user_trace_id: TraceId;
  signed_at: Date;
  signed_by: string;
  type: SignoffType;
};

export type ChecklistTaskResponse = {
  group: FinancialCloseGroupType;
  name: string;
  trace_id: TraceId | undefined; // in EXPENSES_AND_RECONCILIATION group only
  signoffs: ChecklistSignoff[];
  upload_status: Record<string, UploadStatusItem>;
};

export type ChecklistTasksResponse = { tasks: ChecklistTaskResponse[] };

export type UploadStatusFile = {
  procedure_name?: string;
  file_name?: string;
  file_url?: string;
  rows_count?: number | null;
  created_at?: string;
  uploaded_by?: string;
};

export type UploadStatusItem = {
  title: string;
  status: string;
  files: UploadStatusFile[];
};

export type UploadStatus = {
  taskType?: FinancialCloseTaskType;
  title?: string;
  items: FxRateStatusItem[] | UploadStatusItem[];
};

export type FxRateStatusItem = {
  currency_from: string;
  currency_to: string;
  contract_version_count: number;
  expected_fx_rate_count: number;
  fx_rate_count: number;
  lab_count: number;
  site_count: number;
  po_count: number;
  invoice_count: number;
};

export enum FinancialCloseUploadType { // Keep this in sync with hanaq/abacus/models/financial_close.py
  PO_LISTING = 'PO_LISTING',
  INVOICE_LISTING = 'INVOICE_LISTING',
  EDC_SCHEDULED_VISITS = 'EDC_SCHEDULED_VISITS',
  EDC_UNSCHEDULED_VISITS = 'EDC_UNSCHEDULED_VISITS',
  EDC_SCREEN_FAILS = 'EDC_SCREEN_FAILS',
  EDC_PROCEDURES = 'EDC_PROCEDURES',
  SITE_LISTING = 'SITE_LISTING',
}

export enum FinancialCloseTaskType { // Keep this in sync with hanaq/abacus/models/financial_close.py
  UPLOAD_PO_LISTING = 'UPLOAD_PO_LISTING',
  UPLOAD_INVOICE_LISTING = 'UPLOAD_INVOICE_LISTING',
  UPDATE_CURRENT_CONTRACTS_AND_AIPS = 'UPDATE_CURRENT_CONTRACTS_AND_AIPS',
  UPDATE_PATIENT_DATA = 'UPDATE_PATIENT_DATA',
  UPLOAD_SITES_AND_LABS = 'UPLOAD_SITES_AND_LABS',
  OTHER_CLINICAL_CONTRACTS = 'OTHER_CLINICAL_CONTRACTS',
  REVIEW_ACCRUAL_CALCULATIONS = 'REVIEW_ACCRUAL_CALCULATIONS',
  GENERATE_JOURNAL_ENTRY = 'GENERATE_JOURNAL_ENTRY',
  UPLOAD_FX_RATES = 'UPLOAD_FX_RATES',
}

export enum FinancialCloseGroupType { // Keep this in sync with hanaq/abacus/models/financial_close.py
  DATA_COLLECTION = 'DATA_COLLECTION',
  EXPENSES_AND_RECONCILIATION = 'EXPENSES_AND_RECONCILIATION',
  ACCRUALS_AND_JOURNAL_ENTRY = 'ACCRUALS_AND_JOURNAL_ENTRY',
}

export type OccReconResponse = {
  rows: CroReconRow[];
  bla_list: CroReconRow[];
};

export type OccReconRecordRequest = BaseResponseFields & {
  contract_budget_record: TraceId;
  percent_recognized: number;
  reported_ltd_expense: number;
  adjustment_type?: string;
  adjustment_amount?: number;
};

type CroReconBlaRow = BaseRequestFields & {
  id: string;
  totalPrice: number;
  activityDescription?: string;
  adjustmentType?: keyof typeof CroAdjustmentType;
  aipLtdExpensed: number;
  finalReconciledExpense: number;
  vendorReportedBlaAmountNative?: number;
  vendorReportedBlaAmountDefault?: number;
  nativeCurrency?: string;
};

export type CroReconResponse = {
  rows: CroReconRow[];
  activity_groups: CroReconActivityGroup[];
  topside_adjustment?: number;
  bottom_line_adjustments?: CroReconBlaRow[];
  aip_bottom_line_adjustments?: CroReconBlaRow[];
  bla_resolution_type?: BlaResolutionType;
};

export type CroReconRow = BaseRequestFields & {
  id?: string;
  serviceCategory?: string;
  costCategory?: string;
  displayRowNum?: number;
  region?: string;
  activityDescription?: string;
  activityCode?: string;
  activityDriverTraceId?: TraceId;
  orderIndex?: string;
  groupName?: string;
  hasEnrollment?: boolean;
  hasFollowup?: boolean;
  hasTreatment?: boolean;
  startDate?: string;
  endDate?: string;
  ltdExpensed?: number;
  ltdUnits?: number;
  monthlyExpenseUnitsTotal?: number;
  normalizedUnits?: number;
  parameterCalculatedUnitCount?: number;
  parameterCalculatedUnitPrice?: number;
  percentRecognized?: number;
  pizzaUnitCount?: number;
  pizzaUnitPrice?: number;
  pizzaUnitType?: string;
  totalPrice?: number;
  totalPriceCurrency?: number;
  unitCount?: number;
  unitDetail?: string;
  unitType?: string;
  aipActivityDriverTraceId?: TraceId;
  aipEndDate?: string;
  aipHasEnrollment?: boolean;
  aipHasFollowup?: boolean;
  aipHasTreatment?: boolean;
  aipLtdExpensed?: number;
  aipParameterCalculatedUnitCount?: number;
  aipParameterCalculatedUnitPrice?: number;
  aipPercentRecognized?: number;
  aipAssignedRegion?: string;
  aipStartDate?: string;
  aipUnitDetail?: string;
  aipUnitType?: string;
  reportedUnitType?: string;
  reportedNumberOfLTDUnits?: number;
  reportedUnitPrice?: number;
  reportedLtdExpensed?: number;
  isTopsideAdjustment?: boolean;
  adjustmentType?: keyof typeof CroAdjustmentType | 'Top-side adj.';
  adjustmentAmount?: number;
  finalReconciledExpense?: number;
  vendorName?: string;
  contractContainerTraceId?: TraceId;
  traceId?: TraceId;
  nativeCurrency?: string;
  isBottomLineAdjustment?: boolean;
  hasAip?: boolean;
  hasVendor?: boolean;
  hasContract?: boolean;
};

export enum CroReconGroupType {
  AiP,
  Current,
  Vendor,
}

export type CroReconActivityGroup = BaseRequestFields & {
  activity_group_trace_id: string;
  order_index: number;
  adjustment_amount?: number;
  adjustment_type?: string;
  current_ltd_expense?: number;
  aip_ltd_expense?: number;
  final_reconciled_expense?: number;
};

export type CroReconLineMatchings = BaseRequestFields & {
  currentBudgetRows: CroReconActivity[];
  aipBudgetRows: CroReconActivity[];
  reportedActivityRows: CroReconActivity[];
};

type CroReconActivity = {
  id: string;
  region?: string;
  serviceCategory: string;
  activityCode: number;
  activityDescription: string;
  orderIndex?: number;
  newGroupIndex?: number;
};

export type CroReconTopsideAdjustment = BaseRequestFields & {
  topside_adjustment?: number | null;
};

export type CroReconBottomLineAdjustmentResolution = BaseRequestFields & {
  bla_resolution_type?: BlaResolutionType;
};

export type CroInvestigatorFeesAdjustments = BaseRequestFields & {
  site_trace_id: string;
  period_trace_id: string;
  adjustment_type: string;
  adjustment_amount: number | null;
  reported_ltd_expense: number | null;
};

export type VendorRepActivitySnapshotResponse = BaseResponseFields & {
  contract: TraceId;
};

export enum ContractStatusOptions {
  CURRENT = 'Current',
  AIP = 'Amendment In Progress',
  SUPERSEDED = 'Superseded',
  VOIDED = 'Voided',
  FUTURE = 'Future',
}

export enum UserStatusOptions {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type BackendContractStatus =
  | 'AIP'
  | 'CURRENT'
  | 'FUTURE'
  | 'SUPERSEDED'
  | 'VOIDED';
export type BackendContractVersion = 'AMENDMENT' | 'LOI' | 'OG_WORK_ORDER';
export const backendValueFromStatus = (status?: ContractStatusOptions) => {
  switch (status) {
    case ContractStatusOptions.CURRENT:
      return 'CURRENT';
    case ContractStatusOptions.AIP:
      return 'AIP';
    case ContractStatusOptions.SUPERSEDED:
      return 'SUPERSEDED';
    case ContractStatusOptions.VOIDED:
      return 'VOIDED';
    case ContractStatusOptions.FUTURE:
      return 'FUTURE';
    default:
      return undefined;
  }
};

export const statusFromBackendValue = (
  backendStatus?: BackendContractStatus,
) => {
  switch (backendStatus) {
    case 'CURRENT':
      return ContractStatusOptions.CURRENT;
    case 'AIP':
      return ContractStatusOptions.AIP;
    case 'SUPERSEDED':
      return ContractStatusOptions.SUPERSEDED;
    case 'VOIDED':
      return ContractStatusOptions.VOIDED;
    case 'FUTURE':
      return ContractStatusOptions.FUTURE;
    default:
      return undefined;
  }
};
export type RegionAssumptionGroups = BaseRequestFields & {
  regions_to_delete?: TraceId[];
  region_groups_to_delete?: TraceId[];
  region_groups_to_update_or_create?: Array<{ name: string }>;
  regions_to_update_or_create?: Array<{ name: string }>;
  assumption_groups?: Array<{
    name: string;
    parameters: Array<{
      region?: TraceId | string; // Can be region_trace_id or region_name
      value: number | string;
      date_assumption_type?: DateAssumptionType;
    }>;
  }>;
};

export type ApiFilters = Record<
  string,
  number[] | string[] | number | string | undefined
>;
export type ExtraRouteApiFiltersWithPath = {
  trace_id?: string;
  filters: ApiFilters;
  page?: number;
};

/**
 * @deprecated
 * This type was a shortcut when our data models were rapidly changing. Please define a more specific type.
 */
export type GenericData = {
  [key: string]: string | null | undefined; // TODO: It's likely only either null or undefined is possible, not both
};

export type SiteGridRow = GenericData;

export type LabGridRow = GenericData;

export type SiteCostGridResponse = { rows: SiteGridRow[] };
export type LabCostGridResponse = { rows: LabGridRow[] };

export type InvestigatorFeesRow = GenericData;

export type InvestigatorFeesGridResponse = {
  rows: InvestigatorFeesRow[];
};

export type PatientJourneyCombinedGridsResponse = {
  grids?: PatientJourneyGridResponse[];
  isLoading: boolean;
};

export type ManageExpectedVisitRow = {
  patient_id: string;
  journey_id: string;
  visit_name: string;
  visit_date: string;
  cohort_name: string;
  site_number: string;
  use_in_calculations: boolean;
  trace_id?: TraceId;
};

export type ExpectedVisitCombinedResponse = {
  trace_id?: TraceId;
  expected_visits: ManageExpectedVisitRow[];
};

export type ManageExpectedVisitGridResponse = {
  rows: ManageExpectedVisitRow[];
};

type PatientJourneyGridResponse = {
  cohort_id: string;
  rows: PatientJourneyRowResponse[];
};

export type PatientJourneyRowResponse = {
  patient_subject_id: string;
  patient_id: TraceId;
  patient_site_no: string;
  patient_site_name: string;
  patient_status: string;
  visits_total: number;
  // Intentionally set to optional to allow for the possibility of undefined values
  // which are replaced by 0 in frontend total calculations
  expected_visits_total?: number;
  procedures_total: number;
  visits?: GenericData;
  expected_visits?: GenericData;
  procedures?: GenericData;
};

export type PatientActivityRow = GenericData;

export type PatientActivityGridResponse = { rows: PatientActivityRow[] };

export type PeriodResponse = BaseResponseFields & {
  end_date: string;
  trial: TraceId;
  is_closed: boolean;
  latest_version: TraceId;
  latest_edc_snapshot?: TraceId | null;
};

export type PeriodVersionResponse = BaseResponseFields & {
  period: TraceId;
  version_number: number;
  closed_at: string | null;
};

// IF YOU CHANGE THIS, PLEASE CHANGE hanaq/abacus/models/period_versions.py
export enum PeriodGridBlobType { // CRO
  CLINICAL_EXPENSE_SUMMARY = 'COV',
  CRO_EXPENSE_SUMMARY = 'CES',
  CRO_VERSION_HISTORY = 'CVH',
  CRO_CONTRACT_EXPENSES = 'CCE',
  CRO_DIRECT_FEES_EXPENSE_GRID = 'CDE',
  CRO_DIRECT_FEES_RECONCILIATION_GRID = 'CDR',
  CRO_PASS_THROUGHS_EXPENSE_GRID = 'CPE',
  CRO_PASS_THROUGHS_RECONCILIATION_GRID = 'CPR',
  CRO_INVESTIGATOR_FEES_EXPENSE_GRID = 'CFE',
  CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID = 'CRE',
  CRO_CONTRACT_BUDGET = 'CBU',

  // OCC
  OCC_SUMMARY = 'OCS',
  OCC_VERSION_HISTORY = 'OVH',
  OCC_EXPENSE_GRID = 'OEX',
  OCC_RECONCILIATION_GRID = 'ORG',
  OCC_CONTRACT_BUDGET = 'OBU',

  // Trial
  PERIOD_FILES = 'TFI', // while this has been renamed to be more in line with what it is, we have to support older closed periods which have the old type
  TRIAL_FILES = 'FIT',
  ACCRUALS = 'ACR',
  SITE_COST_MATRIX = 'SCM',
  LAB_COST_MATRIX = 'LCM',
  PATIENT_ACTIVITY = 'PAM',
  PATIENT_JOURNEY = 'PJY',
  EXPECTED_VISITS_GRID = 'EVJ',
  JOURNAL_ENTRY = 'JEY',
  PO_COMPLETENESS = 'POC',
  TRIAL_ACTIVITY_SITES = 'TAS',
  TRIAL_ACTIVITY_ENROLLMENT = 'TAE',
  TRIAL_EXPENSE_SUMMARY_GRID = 'TES',
  BS_FLUX = 'BSF',

  // Company
  PO_LISTING = 'POL',
  INVOICE_LISTING = 'ILI',
  GL_ACCOUNTS = 'GLA',
  FX_RATE = 'FXR',
  COMPANY_FILES = 'CFI',
  USER_ACCESS_GRID = 'UAS',
}

export const PERIOD_AWARE_GRID_BLOB_TYPES = [
  PeriodGridBlobType.TRIAL_FILES,
  PeriodGridBlobType.PERIOD_FILES,
  PeriodGridBlobType.COMPANY_FILES,
  PeriodGridBlobType.ACCRUALS,
  PeriodGridBlobType.JOURNAL_ENTRY,
  PeriodGridBlobType.CLINICAL_EXPENSE_SUMMARY,
  PeriodGridBlobType.CRO_EXPENSE_SUMMARY,
  PeriodGridBlobType.OCC_SUMMARY,
  PeriodGridBlobType.OCC_RECONCILIATION_GRID,
  PeriodGridBlobType.OCC_EXPENSE_GRID,
  PeriodGridBlobType.OCC_VERSION_HISTORY,
  PeriodGridBlobType.CRO_VERSION_HISTORY,
  PeriodGridBlobType.GL_ACCOUNTS,
  PeriodGridBlobType.LAB_COST_MATRIX,
  PeriodGridBlobType.PATIENT_ACTIVITY,
  PeriodGridBlobType.PATIENT_JOURNEY,
  PeriodGridBlobType.EXPECTED_VISITS_GRID,
  PeriodGridBlobType.SITE_COST_MATRIX,
  PeriodGridBlobType.FX_RATE,
  PeriodGridBlobType.PO_LISTING,
  PeriodGridBlobType.INVOICE_LISTING,
  PeriodGridBlobType.PO_COMPLETENESS,
  PeriodGridBlobType.CRO_CONTRACT_BUDGET,
  PeriodGridBlobType.OCC_CONTRACT_BUDGET,
  PeriodGridBlobType.CRO_DIRECT_FEES_EXPENSE_GRID,
  PeriodGridBlobType.CRO_DIRECT_FEES_RECONCILIATION_GRID,
  PeriodGridBlobType.CRO_INVESTIGATOR_FEES_EXPENSE_GRID,
  PeriodGridBlobType.CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID,
  PeriodGridBlobType.CRO_PASS_THROUGHS_EXPENSE_GRID,
  PeriodGridBlobType.CRO_PASS_THROUGHS_RECONCILIATION_GRID,
  PeriodGridBlobType.BS_FLUX,
  PeriodGridBlobType.TRIAL_ACTIVITY_SITES,
  PeriodGridBlobType.TRIAL_ACTIVITY_ENROLLMENT,
  PeriodGridBlobType.TRIAL_EXPENSE_SUMMARY_GRID,
  PeriodGridBlobType.USER_ACCESS_GRID,
] as const;

export type PeriodifiedGridBlobType =
  (typeof PERIOD_AWARE_GRID_BLOB_TYPES)[number];

export type PeriodGridBlobResponse = BaseResponseFields & {
  period: TraceId;
  col_defs: string;
  rows: string;
  grid_options?: string;
  saved_object_type: PeriodifiedGridBlobType;
};

// IF YOU CHANGE THIS, PLEASE CHANGE hanaq/abacus/models/period_versions.py
export enum PeriodRecordBlobType {
  TRIAL = 'TRL',
  COMPANY = 'COM',
  CONTRACT_ASSUMPTIONS = 'CAS',
  CONTRACT_CONTAINER = 'CUR',
  CONTRACT_CONTAINER_FILES = 'CRF',
  CONTRACT_VERSION = 'CVE',
  CONTRACT_VERSION_HEADER = 'CVH',
  CONTRACT_VERSION_STATUS = 'CVS',
  VARIANCE_THRESHOLD = 'VAT',
  FINANCIAL_CLOSE_CHECKLIST = 'FCC',
  CRO_CONTRACT_VERSION_FILES = 'CCF',
  OCC_CONTRACT_VERSION_FILES = 'OCF',
  OCC_ACTIVITY_DRIVER_VALIDATION = 'OAD',
  PASS_THROUGH_ACTIVITY_DRIVER_VALIDATION = 'PAD',
  DIRECT_FEES_ACTIVITY_DRIVER_VALIDATION = 'DAD',
}

export const PERIOD_AWARE_RECORD_BLOB_TYPES = [
  PeriodRecordBlobType.CONTRACT_VERSION,
  PeriodRecordBlobType.CONTRACT_CONTAINER,
  PeriodRecordBlobType.CONTRACT_CONTAINER_FILES,
  PeriodRecordBlobType.TRIAL,
  PeriodRecordBlobType.FINANCIAL_CLOSE_CHECKLIST,
  PeriodRecordBlobType.CONTRACT_ASSUMPTIONS,
  PeriodRecordBlobType.CONTRACT_VERSION_HEADER,
  PeriodRecordBlobType.CONTRACT_VERSION_STATUS,
  PeriodRecordBlobType.OCC_ACTIVITY_DRIVER_VALIDATION,
  PeriodRecordBlobType.DIRECT_FEES_ACTIVITY_DRIVER_VALIDATION,
  PeriodRecordBlobType.PASS_THROUGH_ACTIVITY_DRIVER_VALIDATION,
  PeriodRecordBlobType.CRO_CONTRACT_VERSION_FILES,
  PeriodRecordBlobType.OCC_CONTRACT_VERSION_FILES,
] as const;

export type PeriodifiedRecordBlobType =
  (typeof PERIOD_AWARE_RECORD_BLOB_TYPES)[number];

export type PeriodRecordBlobResponse = BaseResponseFields & {
  period: TraceId;
  blob: string;
  saved_object_type: PeriodifiedRecordBlobType;
};

// IF YOU CHANGE THIS, PLEASE CHANGE hanaq/abacus/models/period_versions.py
export enum PeriodGraphBlobType {
  TRIAL_EXPENSE_AND_ENROLLMENT = 'TEE',
}

export const PERIOD_AWARE_GRAPH_BLOB_TYPES = [
  PeriodGraphBlobType.TRIAL_EXPENSE_AND_ENROLLMENT,
] as const;

export type PeriodifiedGraphBlobType =
  (typeof PERIOD_AWARE_GRAPH_BLOB_TYPES)[number];

export type PeriodGraphBlobResponse = BaseResponseFields & {
  period_version: TraceId;
  graph_data: string;
  graph_options: string;
  saved_object_type: PeriodifiedGraphBlobType;
};

// IF YOU CHANGE THIS, PLEASE CHANGE hanaq/abacus/models/period_versions.py
export enum PeriodMenuItemType { // CRO "real" ids -> "pmi" ids
  CRO_CONTRACT_CONTAINER = 'CCC',
  CRO_CONTRACT_VERSION = 'CCV',

  // CRO tabs
  CRO_OVERVIEW = 'COV',
  CRO_CURRENT_CONTRACT = 'CCR',
  CRO_AMENDMENT_IN_PROGRESS = 'CAP',

  // OCC "real" ids -> "pmi" ids
  OCC_CONTRACT_CONTAINER = 'OCC',
  OCC_CONTRACT_VERSION = 'OCV',

  // this is used to keep track of what the "current-contract" and "amendment-in-progress"
  // tabs that a given CRO contract point to as those don't link to contract version id's
  // directly, rather via generic urls of `current-contract` and `amendment-in-progress`.
  // this should basically be a simple hash to get the ids for the `CRO tabs` section above
  CRO_CONTRACT_CONTAINER_TABS = 'CCT',

  // This is used to keep track of patient cohort tabs in a patient journey
  JOURNEY_COHORT_TABS = 'PJT',

  // Keeps track of base currency tabs in FX rates
  FX_RATE_TABS = 'FXT',
}

export type PeriodMenuItemResponse = BaseResponseFields & {
  period: TraceId;
  blob?: string;
  saved_object_type: PeriodMenuItemType;
  live_slug: TraceId | string;
  sort_order?: number;
};

export type PeriodBlob = { title: string };

export enum CroAdjustmentType {
  NONE = 'None',
  CROR = 'CRO Reported',
  AMIP = 'Amendment-in-Progress',
  OTHR = 'Other',
}
// Ideally the `CroAdjustmentTypeReverse` mapping would be generated from the
// `CroAdjustmentType` enum but when we did that using Object.entries we lost type safety
export const CroAdjustmentTypeReverse = {
  [CroAdjustmentType.NONE]: 'NONE',
  [CroAdjustmentType.CROR]: 'CROR',
  [CroAdjustmentType.AMIP]: 'AMIP',
  [CroAdjustmentType.OTHR]: 'OTHR',
};

export enum OccAdjustmentType {
  NONE = 'None',
  CROR = 'OCC Reported',
  AMIP = 'Amendment-in-Progress',
  OTHR = 'Other',
}
// Ideally the `OccAdjustmentTypeReverse` mapping would be generated from the
// `OccAdjustmentType` enum but when we did that using Object.entries we lost type safety
export const OccAdjustmentTypeReverse = {
  [OccAdjustmentType.NONE]: 'NONE',
  [OccAdjustmentType.CROR]: 'CROR',
  [OccAdjustmentType.AMIP]: 'AMIP',
  [OccAdjustmentType.OTHR]: 'OTHR',
};

export type ContractBudgetSnapshotResponse = BaseResponseFields & {
  activity_assignment_type?: string;
  vendor_name?: string;
  records?: ContractBudgetRecordResponse[];
};

export type ContractBudgetRecordRequest = BaseRequestFields & {
  snapshot_row?: number;
  created_at?: string;
  inferred_rollup_row?: boolean;
  confirmed_rollup_row?: boolean;
  cost_category?: string;
  service_category?: string;
  activity_code?: string;
  activity_description?: string;
  assigned_region: TraceId | null;
  assigned_region_group: TraceId | null;
  region_name?: string;
  pizza_unit_type?: string;
  unit_count?: string;
  pizza_unit_price?: number;
  unit_price_currency?: string;
  total_price?: number;
  total_price_currency?: string;
  activity_driver?: TraceId;
};

export type ContractBudgetRecordResponse = {
  trace_id: TraceId;
  snapshot_row?: number;
  created_at?: string;
  inferred_rollup_row?: boolean;
  confirmed_rollup_row?: boolean | null;
  cost_category?: string;
  service_category?: string;
  activity_code?: string;
  activity_description?: string;
  assigned_region: RegionResponse | null;
  assigned_region_group: RegionGroupResponse | null;
  region_name?: string;
  pizza_unit_type?: string;
  unit_count?: string;
  pizza_unit_price?: number;
  unit_price_currency?: string;
  total_price?: number;
  total_price_currency?: string;
  activity_driver?: TraceId | null;
};

export type ActivityDriverKeyValuesRequestParams = Record<string, string>;

export type ActivityDriverRequestParams = {
  contract: string;
  unit_type: string;
  unit_detail: string;
  description?: string;
  service_category?: string;
  pizza_unit_type?: string;
  pizza_unit_price?: number;
  trace_id?: TraceId;
  key_values?: ActivityDriverKeyValuesRequestParams;
};

export type ActivityDriverResponse = ActivityDriverRequestParams &
  BaseResponseFields & { unit_price?: number };

export type AccrualsDataRequest = BaseRequestFields & {
  long_term_prepaid: number | null;
  period: TraceId;
  contract_container: TraceId;
  gl_account: TraceId;
};

export type PeriodHeader = { label: string; field: string; endDate: string };

export type FxRateResponse = BaseResponseFields & {
  company: TraceId;
  currency_from: string;
  currency_to: string;
  rate: string | null;
  end_date: string;
};

export type TrialAssumptionsResponse = BaseResponseFields & {
  trial: TraceId;
  countries_count: number | null;
  sites_count: number | null;
  patients_to_enroll: number | null;
  patients_to_screen: number | null;
  est_screen_fail_rate: string | null;
  est_drop_out_rate: string | null;
  start_up_start_date: string;
  start_up_end_date: string | null;
  treatment_start_date: string | null;
  treatment_end_date: string | null;
  close_start_date: string | null;
  close_end_date: string | null;
};

export type PurchaseOrderRecordResponse = BaseResponseFields & {
  snapshot_row?: number;
  date?: string;
  po_number: string;
  po_amount: string;
  vendor: string;
  gl_account: string;
  status: string;
  description?: string;
  currency: string;
  study_id: string; // trial ID
};

export type AccrualsGridRow = {
  po_numbers: string[];
  gl_account_number: string;
  vendor_name: string;
  vendor_type: string;
  cost_categories: string;
  native_contract_value: number;
  default_contract_value: number;
  classification_status: string;
  unadjusted_balance: number;
  balance_in_ap: number;
  balance_sheet_netdown: number;
  accrued_expense: number;
  short_term_prepaid: number;
  invoiced: number;
  paid: number;
  reconciled_expense: number;
  percent_recognized: number;
};

export type BsFluxRow = {
  po_numbers: string[];
  gl_account_number: string;
  vendor_name: string;
  vendor_type: string;
  cost_categories: string;
  native_contract_value: number;
  default_contract_value: number;
  accrued_expense: number;
  short_term_prepaid: number;
  reconciled_expense: number;
  period2_accrued_expense: number;
  period2_short_term_prepaid: number;
  period2_reconciled_expense: number;
};

export type DefaultParameterResponse = Record<
  ForecastParameterType,
  Record<string, number>
>;

export type ForecastRequest = {
  name: string;
  trial: TraceId;
  start_period: TraceId;
  folder?: TraceId;
};

export type ForecastExpenses = {
  [key: string]:
    | Record<string, number>
    | string[]
    | number
    | string
    | undefined; // for month names
  vendor: string;
  cost_category: string;
};

export enum ForecastParameterType { // Keep this in sync with the choices defined in the forecast parameter
  ENROLLMENT_RATE = 'ENR',
  SITE_ACTIVATION_RATE = 'SAR',
}

export type ForecastParameterRequest = BaseRequestFields & {
  forecast: TraceId;
  type: ForecastParameterType;
  region_name: string;
  value: number;
};

export type ForecastParameterResponse = BaseResponseFields & {
  forecast: TraceId;
  type: ForecastParameterType;
  region_name: string;
  value: number;
};

export type ForecastResponse = BaseResponseFields & {
  name: string;
  trial: TraceId;
  locked: boolean;
  start_period: TraceId;
  folder?: TraceId;
};

export type ForecastFolderRequest = BaseRequestFields & {
  name: string;
  trial: TraceId;
};

export type ForecastFolderResponse = BaseResponseFields & {
  name: string;
  trial: TraceId;
  sort_order: number;
  forecasts?: ForecastResponse[];
};

export type ForecastedValueResponse = Record<string, number | string | null>;

export type ForecastedGridsResponse = {
  forecasted_months: number | undefined;
  actual_months: string[];
  months_contracted: number;
  earliest_start_date: string;
  latest_close_date: string;
  expenses: ForecastExpenses[];
  patients: ForecastedValueResponse[];
  patients_contracted: number;
  sites: ForecastedValueResponse[];
  sites_contracted: number;
  actual_sites_count: number;
  actual_patients_count: number;
  forecasted_sites_count: number;
  forecasted_patients_count: number;
};

export type PurchaseOrderSnapshotResponse = BaseResponseFields & {
  comment?: string;
  records: PurchaseOrderRecordResponse[];
};

export type KeyValueRow = {
  key: string;
  value?: Array<number | string> | number | string | undefined;
};

export type MenuItemType = {
  title: string;
  link: string;
  contract_container?: ContractContainerResponse;
  sort_order?: number;
  current_contract?: ContractVersionResponse;
  aip_contract?: ContractVersionResponse;
};

export type PatientJourneyMenuItemType = {
  title: string;
  link: string;
  key: string;
  sort_order?: number;
};

type UserResponse = {
  first_name: string | undefined;
  last_name: string | undefined;
  trace_id: TraceId;
};

export type UserManagementGridPopupData = {
  buttonLabel: string;
  title: string;
  message: string;
  state: boolean;
  trace_id: string;
  dataToUpdate: Record<string, string[] | boolean | string>;
};

export type CommentResponse = BaseResponseFields &
  CommentRequest & { seen_by_current_user: boolean; user: UserResponse | null };

export type CommentRequest = BaseRequestFields & {
  period_version: TraceId;
  location_type: string;
  location_slug?: TraceId;
  text: string;
  files?: Array<{ name: string; file: File }>;
};

export type EmergencyAlertResponse = BaseResponseFields & {
  severity: AlertColor;
  dismissable: boolean;
  title: string;
  body: string;
};

export type VersionHistoryRow = {
  contract_container_trace_id?: TraceId;
  contract_version_trace_id?: TraceId;
  execution_date?: string;
  last_used_date?: string;
  gross_contract_value?: number;
  net_contract_value?: number;
  po_number?: string;
  status?: BackendContractStatus;
  version?: string;
  version_name?: string;
  vendor_name?: string;
  create_new: boolean;
  change_from_previous?: number;
};

export enum CommentLocationType { // Keep this in sync with the choices defined in the comment model
  // And also with FileObjectMixin.FileCategoryChoices
  DEMO = 'DEM',
  HOME = 'HOM',
  CRO_RECON = 'CRA',
  OCC_RECON = 'ORA',
  FX_RATE = 'FXR',
  BS_FLUX = 'BSF',
  DATA_COLLECTION = 'FDC',
  EXPENSES_AND_RECONCILIATION = 'FER',
  ACCRUALS_AND_JOURNAL_ENTRY = 'FAJ',
  MANAGE_EXPECTED_VISITS = 'MVJ',
}

type CurrencyValues = Record<CurrencyViewMode, number>;
type CurrencyCodes = Record<CurrencyViewMode, CurrencyCode>;

export type SharedContractExpenseSummaryRow = {
  contract_container: string;
  current_contract_value: CurrencyValues;
  currency: CurrencyCodes;
  current_ltd_expensed: CurrencyValues;
  vendor_reported: CurrencyValues;
  reconciled_expense: CurrencyValues;
  ltd_invoiced: CurrencyValues;
  aip_ltd_expensed?: CurrencyValues; // CRO only (used in shared colDefs)
};

export type CroExpenseSummaryRow = SharedContractExpenseSummaryRow & {
  cost_category: CroCostCategory;
  aip_ltd_expensed: number;
  contract_value_mismatch?: boolean; // Optional due to closed periods
};

export type TrialExpenseSummaryGridResponse = {
  rows: TrialExpenseSummaryRow[] | undefined;
  month_list: string[];
};

export type TrialExpenseSummaryRow = {
  [key: string]:
    | Record<string, number>
    | string[]
    | number
    | string
    | undefined; // for month names
  current_contract_value: number;
  cost_category: CroCostCategory;
  vendor_name: string;
  po_numbers: string[];
  vendor_type: string;
  booked_expenses: { [key: string]: number };
};

export type OccExpenseSummaryRow = SharedContractExpenseSummaryRow & {
  contract_container_trace_id: TraceId;
  vendor_name: string;
  po_numbers: string;
};

export type TrialFilesGridRow = {
  id: string;
  file_name: string;
  file_url: string;
  file_category: string;
  file_type: 'condor' | 'contract' | 'site' | 'unmapped';
  vendor: string;
  uploaded: string;
};

export type CompanyFilesGridRow = {
  trace_id: string;
  file_name: string;
  file_url: string;
  vendor: string;
  file_category: string;
  uploaded: string;
};

export type ContractContainerPeriodMenuItem = {
  contractContainer: ContractContainerResponse;
  menuItem: PeriodMenuItemResponse;
  amendmentInProgressMenuItem?: PeriodMenuItemResponse;
  currentContractMenuItem?: PeriodMenuItemResponse;
  overviewMenuItem?: PeriodMenuItemResponse;
  contractVersions?: ContractVersionResponse[];
  contractVersionMenuItems?: ContractVersionPeriodMenuItemRecord;
  current_contract?: ContractVersionResponse;
  aip_contract?: ContractVersionResponse;
};

export type ContractVersionPeriodMenuItemRecord = Record<
  TraceId,
  PeriodMenuItemResponse
>;

export type JournalEntryRow = {
  date: string;
  gl_account: string;
  description?: string;
  debit: number;
  credit: number;
};
export type UserRow = {
  trace_id?: string;
  name: string;
  email: string;
  company_name: string;
  status: string;
  picture?: string;
  title?: string;
  is_superuser?: boolean;
  can_signoff_as_preparer?: boolean;
  can_signoff_as_reviewer?: boolean;
  is_assigned_reviewer?: string[];
  is_assigned_preparer?: string[];
  invite_accepted?: boolean;
  is_blocked?: boolean;
  company_group?: boolean;
  trial_access: string[];
};

type TrialActivitySitesRow = {
  [key: string]: number | string | undefined;
  type: string;
};

export type TrialActivitySitesResponse = {
  new_sites: TrialActivitySitesRow;
  cumulative_sites: TrialActivitySitesRow;
  closed_sites: TrialActivitySitesRow;
  active_months: TrialActivitySitesRow;
};

export type TrialActivityEnrollmentRow = {
  [key: string]: number | string | undefined;
  type: string;
};

export type TrialActivityEnrollmentGridResponse = {
  enrolled_patients: TrialActivityEnrollmentRow;
  cumulative_enrollment: TrialActivityEnrollmentRow;
  patients_dropped_or_completed: TrialActivityEnrollmentRow;
  active_patients: TrialActivityEnrollmentRow;
};

export type TrialHistoricalValuesRow = {
  cost_category: string;
  po_numbers: string[];
  vendor_name: string;
  vendor_type: VendorType;
  months: Array<Record<string, number | undefined>>;
};
export type TrialHistoricalValuesGridResponse = {
  rows: TrialHistoricalValuesRow[];
};

export type TrialClosedPeriodValuesRow = {
  cost_category: string;
  po_numbers: string[];
  vendor_name: string;
  vendor_type: VendorType;
  period_version: string;
  months: Array<Record<string, number | undefined>>;
};
export type TrialClosedPeriodValuesGridResponse = {
  rows: TrialClosedPeriodValuesRow[];
};

export type ActivityDriverValidation = {
  activity_driver_count?: number | null;
  contract_budget_record_count?: number | null;
};

export type VisitType =
  | 'isDroppedCompleted'
  | 'isEnroll'
  | 'isRegular'
  | 'isScreen'
  | 'isScreenfail';

export type ContentTypeResponse = { id: number; name: string };

export type AuditLogResponse = Omit<BaseResponseFields, 'created_at'> & {
  created_at: string;
  trial?: {
    trace_id: string;
    phase: string;
    status: string;
    indication: string;
    study_id: string;
  };
  period?: { trace_id: string; end_date: string };
  content_type: ContentTypeResponse;
  object_trace_id: string;
  object_representation?: string;
  action: number;
  actor: { first_name: string; last_name: string; trace_id: string };
  // these can both literally be "anything"
  changes?: Record<string, ManyToManyAuditLogEntry | string[]>;
  additional_data?: Record<string, string[]>;
};

export type AuditLogGridResponse = {
  rows: AuditLogResponse[];
  count: number;
};

export type ManyToManyAuditLogEntry = {
  type: string;
  operation: string;
  objects: string[];
};

export type HistoricalValuesExpenseUpsertRecord = {
  contract_container: TraceId;
  cost_category: string;
  end_date: string;
  amount: number;
};

export type UserAddForm = {
  traceId?: string;
  first_name: string;
  last_name: string;
  email: string;
  third_party_companies: ThirdPartyCompanyOptions[];
  title: string;
  trial_access?: string[];
};

export type ThirdPartyCompanyOptions = {
  value: string;
  label: string;
  new?: boolean;
};

export type HistoricalValuesUpsert = {
  expenses: HistoricalValuesExpenseUpsertRecord[];
};

export type ClosedPeriodExpensesUpsertRecord = {
  contract_container: TraceId;
  period_version: TraceId;
  cost_category: string;
  amount: number;
};

export type ClosedPeriodExpensesUpsert = {
  expenses: ClosedPeriodExpensesUpsertRecord[];
};

export enum ControlCodeFrequencyType {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
}

export enum ControlCodeFrequencyTranslation {
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  ANNUALLY = 'Annually',
}

export type ControlCodeResponse = BaseResponseFields & {
  company: string;
  control_code: string;
  control_frequency: ControlCodeFrequencyType;
  description: string;
  user: UserResponse;
};

export enum ControlListingCroContractType { // Keep this in sync with hanaq/abacus/models/control_listing_tasks.py
  CRO_CONTRACTS = 'CRO_CONTRACTS',
}

export type ControlListingTaskType =
  | ControlListingCroContractType
  | FinancialCloseTaskType;

export type ControlListingTaskResponse = BaseResponseFields & {
  company: string;
  control_codes: Array<
    Pick<ControlCodeResponse, 'control_code' | 'description' | 'trace_id'>
  >;
  name: ControlListingTaskType;
  last_updated: { updated_by: string; updated_at: string } | null;
};

export type ControlListingTaskUpsert = BaseResponseFields & {
  company: string;
  control_codes: TraceId[];
  name: string;
};

export enum LockGrid {
  // Keep this in sync with the type defined in LockGridChoices in hanaq
  CRO_DIRECT_FEES_EXPENSE_GRID = 'CDE',
  CRO_DIRECT_FEES_RECONCILIATION_GRID = 'CDR',
  CRO_PASS_THROUGHS_EXPENSE_GRID = 'CPE',
  CRO_PASS_THROUGHS_RECONCILIATION_GRID = 'CPR',
  CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID = 'CRE',
  OCC_EXPENSE_GRID = 'OEX',
  OCC_RECONCILIATION_GRID = 'ORG',
  ACCRUALS_GRID = 'ACC',
  SITE_COST_MATRIX = 'SCM',
  LAB_COST_MATRIX = 'LCM',
}

export enum LockGridStatus {
  LOCK_GRID = 'LOCK_GRID',
  UNLOCK_GRID = 'UNLOCK_GRID',
}

export type LockGridResponse = BaseResponseFields & {
  grid: LockGrid;
  status: LockGridStatus;
  user: UserResponse;
};

export type LockGridUpsert = BaseResponseFields & {
  grid: string;
  trial?: string;
  period?: string;
  status: string;
};

export const INVOICED = 'invoiced';

export const CroCostCategories = [
  'DIRECT_FEES',
  'PASS_THROUGH',
  'INVESTIGATOR_FEES',
] as const;

export type CroCostCategory = (typeof CroCostCategories)[number];

export type PatientCohortErrors = Record<
  TraceId | number,
  PatientCohortError | undefined
>;

export type PatientAssessmentError = { name?: string; mappedName?: string };

export type PatientCohortError = {
  name?: string;
  patientAssessments?: PatientAssessmentErrors;
  mappedName?: string;
};

export type PatientAssessmentErrors = Record<
  TraceId | number,
  PatientAssessmentError | undefined
>;

export type { CurrencyCode };

// this is exposed in MUI, but only in the root module which to allow ESlint to let us use, we need to update to v9, which we can't yet
export type PaletteMode = 'dark' | 'light';

/* eslint-enable import/exports-last */
