import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';

import CommentsWidget from 'shared/components/comments/CommentsWidget';
import LockGridButton from 'shared/components/lock-grid/LockGridButton';
import LockGridReminder from 'shared/components/lock-grid/LockGridReminder';

import CroContractVersionDetail from 'accruals/pages/clinical-expenses/cro/CroContractVersionDetail';
import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import CurrencyToggleGroup from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import { CommentLocationType, LockGrid } from 'shared/lib/types';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import CroContractOverviewTabContent from './CroContractOverviewTabContent';
import CroContractTabs from './CroContractTabs';
import CroReconExpenseSubtabs from './CroReconExpenseSubtabs';
import CroDirectFeesActivityDriverCounts from './components/CroDirectFeesActivityDriverCounts';
import CroPassThroughActivityDriverCounts from './components/CroPassThroughActivityDriverCounts';
import CroReconGridHeader from './components/CroReconGridHeader';
import CroDirectFeesExpensesGrid from './grids/CroDirectFeesExpensesGrid';
import CroDirectFeesReconGrid from './grids/CroDirectFeesReconGrid';
import CroInvestigatorFeesExpensesGrid from './grids/CroInvestigatorFeesExpensesGrid';
import CroInvestigatorFeesReconGrid from './grids/CroInvestigatorFeesReconGrid';
import CroPassThroughsExpensesGrid from './grids/CroPassThroughsExpensesGrid';
import CroPassThroughsReconGrid from './grids/CroPassThroughsReconGrid';

function CroContractContainerPage() {
  const [currencyViewMode, setCurrencyViewMode] =
    useState<CurrencyViewMode>('trial');
  // activetab is one of: 'current-contract' | 'direct-fees' | 'investigator-fees' | 'overview' | 'pass-throughs'
  // activeSubTab is one of: 'expense' | 'recon' | undefined
  const { activeTab, activeSubTab } = useParams();
  const showComments =
    activeSubTab === 'recon' &&
    (activeTab === 'direct-fees' ||
      activeTab === 'pass-throughs' ||
      activeTab === 'investigator-fees');

  // do not pass in any "live" values, as it will break closed periods
  const { contractVersionPeriodMenuItemTraceId } =
    useContractAndPeriodWithVersions();

  const gridSx = useMemo(() => ({ height: '100%' }), []);
  const userDisplayOptions = useMemo(
    () => ({ currencyViewMode }),
    [currencyViewMode],
  );

  return (
    <PageContentLoadingContainer
      tabs={
        <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
          <Box sx={{ flex: 1 }}>
            <CroContractTabs />
            {!!activeSubTab && <CroReconExpenseSubtabs />}
          </Box>
        </Box>
      }
    >
      {activeTab === 'overview' && <CroContractOverviewTabContent />}
      {activeTab === 'direct-fees' && activeSubTab === 'expense' && (
        <FlexGridContainer>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{ mb: 1 }}
          >
            <CroDirectFeesActivityDriverCounts
              parentMenuItem={contractVersionPeriodMenuItemTraceId}
            />
            <CurrencyToggleGroup
              setValue={setCurrencyViewMode}
              testId="CROExpenseGridToggle"
              value={currencyViewMode}
            />
            <LockGridButton grid={LockGrid.CRO_DIRECT_FEES_EXPENSE_GRID} />
          </Box>
          <CroDirectFeesExpensesGrid
            parentMenuItem={contractVersionPeriodMenuItemTraceId}
            sx={gridSx}
            userDisplayOptions={userDisplayOptions}
          />
          <LockGridReminder grid={LockGrid.CRO_DIRECT_FEES_EXPENSE_GRID} />
        </FlexGridContainer>
      )}
      {activeTab === 'direct-fees' && activeSubTab === 'recon' && (
        <FlexGridContainer>
          <Box
            sx={{
              display: 'flex',
              mb: 2,
            }}
          >
            <CroReconGridHeader costCategory="DIRECT_FEES" />
            <CurrencyToggleGroup
              setValue={setCurrencyViewMode}
              testId="CRODirectReconGridToggle"
              value={currencyViewMode}
            />
            <LockGridButton
              grid={LockGrid.CRO_DIRECT_FEES_RECONCILIATION_GRID}
            />
          </Box>

          <CroDirectFeesReconGrid
            parentMenuItem={contractVersionPeriodMenuItemTraceId}
            sx={gridSx}
            userDisplayOptions={userDisplayOptions}
          />
          <LockGridReminder
            grid={LockGrid.CRO_DIRECT_FEES_RECONCILIATION_GRID}
          />
        </FlexGridContainer>
      )}
      {activeTab === 'pass-throughs' && activeSubTab === 'expense' && (
        <FlexGridContainer>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{ mb: 1 }}
          >
            <CroPassThroughActivityDriverCounts
              parentMenuItem={contractVersionPeriodMenuItemTraceId}
            />
            <CurrencyToggleGroup
              setValue={setCurrencyViewMode}
              testId="CROExpenseGridToggle"
              value={currencyViewMode}
            />
            <LockGridButton grid={LockGrid.CRO_PASS_THROUGHS_EXPENSE_GRID} />
          </Box>
          <CroPassThroughsExpensesGrid
            parentMenuItem={contractVersionPeriodMenuItemTraceId}
            sx={gridSx}
            userDisplayOptions={userDisplayOptions}
          />
          <LockGridReminder grid={LockGrid.CRO_PASS_THROUGHS_EXPENSE_GRID} />
        </FlexGridContainer>
      )}
      {activeTab === 'pass-throughs' && activeSubTab === 'recon' && (
        <FlexGridContainer>
          <Box
            sx={{
              display: 'flex',
              mb: 2,
            }}
          >
            <CroReconGridHeader costCategory="PASS_THROUGH" />
            <CurrencyToggleGroup
              setValue={setCurrencyViewMode}
              testId="CROPassReconGridToggle"
              value={currencyViewMode}
            />
            <LockGridButton
              grid={LockGrid.CRO_PASS_THROUGHS_RECONCILIATION_GRID}
            />
          </Box>

          <CroPassThroughsReconGrid
            parentMenuItem={contractVersionPeriodMenuItemTraceId}
            sx={gridSx}
            userDisplayOptions={userDisplayOptions}
          />
          <LockGridReminder
            grid={LockGrid.CRO_PASS_THROUGHS_RECONCILIATION_GRID}
          />
        </FlexGridContainer>
      )}
      {activeTab === 'investigator-fees' && activeSubTab === 'expense' && (
        <FlexGridContainer>
          <CurrencyToggleGroup
            modes={['site', 'native', 'trial']}
            setValue={setCurrencyViewMode}
            sx={{ mb: 2 }}
            testId="CROInvestigatorFeesExpenseToggle"
            value={currencyViewMode}
          />
          <CroInvestigatorFeesExpensesGrid
            parentMenuItem={contractVersionPeriodMenuItemTraceId}
            sx={gridSx}
            userDisplayOptions={userDisplayOptions}
          />
        </FlexGridContainer>
      )}
      {activeTab === 'investigator-fees' && activeSubTab === 'recon' && (
        <FlexGridContainer>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mb={2}
          >
            <CurrencyToggleGroup
              modes={['site', 'native', 'trial']}
              setValue={setCurrencyViewMode}
              testId="CROInvestigatorFeesReconToggle"
              value={currencyViewMode}
            />
            <LockGridButton
              grid={LockGrid.CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID}
            />
          </Box>
          <CroInvestigatorFeesReconGrid
            parentMenuItem={contractVersionPeriodMenuItemTraceId}
            sx={gridSx}
            userDisplayOptions={userDisplayOptions}
          />
          <LockGridReminder
            grid={LockGrid.CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID}
          />
        </FlexGridContainer>
      )}
      {activeTab === 'current-contract' && <CroContractVersionDetail />}
      {activeTab === 'amendment-in-progress' && <CroContractVersionDetail />}
      {showComments && (
        <CommentsWidget
          location={CommentLocationType.CRO_RECON}
          locationSlug={activeTab}
          commentsForPage
        />
      )}
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(CroContractContainerPage);
