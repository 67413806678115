import { useState } from 'react';

import useChecklistSectionData from 'accruals/pages/financial-close/accounting-checklist/tasks-status/useChecklistSectionData';
import EdcProceduresUploadWizard from 'accruals/wizards/csv-upload-wizards/edc-procedures/EdcProceduresUploadWizard';
import type { UploadStatusItem } from 'shared/lib/types';

import TrialUploadsListItem from './TrialUploadsListItem';

function EdcProceduresUploads() {
  const sections = useChecklistSectionData(true);
  const uploadStatus = sections[0]?.tasks?.[3]?.status?.items?.[3];
  const [showUploadProcedures, setShowUploadProcedures] =
    useState<boolean>(false);

  return (
    <>
      <TrialUploadsListItem
        primaryText="EDC - procedures"
        uploadStatus={
          uploadStatus ? (uploadStatus as UploadStatusItem) : undefined
        }
        onUpload={() => setShowUploadProcedures(true)}
      />
      {showUploadProcedures && (
        <EdcProceduresUploadWizard
          fileUploadType="PES"
          onClose={() => setShowUploadProcedures(false)}
          onComplete={() => setShowUploadProcedures(false)}
        />
      )}
    </>
  );
}

export default EdcProceduresUploads;
