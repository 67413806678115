import { Fragment } from 'react/jsx-runtime';
import Typography from '@mui/material/Typography';

import { formatMonthDayYear } from 'shared/helpers/helpers';
import type { UploadStatusFile } from 'shared/lib/types';

type Props = {
  files: UploadStatusFile[];
};

function UploadDateAndUserColumn(props: Props) {
  const { files } = props;

  return (
    <>
      {files.map(({ file_url, created_at, uploaded_by }) => (
        <Fragment key={file_url}>
          <Typography variant="body1">{uploaded_by ?? ''}</Typography>
          <Typography color="text.secondary" variant="caption">
            {created_at === undefined ? '' : formatMonthDayYear(created_at)}
          </Typography>
        </Fragment>
      ))}
    </>
  );
}

export default UploadDateAndUserColumn;
