import { Fragment } from 'react/jsx-runtime';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import type { UploadStatusFile } from 'shared/lib/types';

type Props = {
  files: UploadStatusFile[];
};

function LinkAndRowsColumn(props: Props) {
  const { files } = props;

  return (
    <>
      {files.map(({ file_name, file_url, rows_count }) => {
        const rowText =
          rows_count !== undefined && rows_count !== null
            ? `${rows_count} row${rows_count > 1 ? 's' : ''}`
            : '';

        return (
          <Fragment key={file_url}>
            {!!file_name && !!file_url && (
              <Box
                component="a"
                href={file_url}
                rel="noreferrer"
                sx={{ display: 'block' }}
                target="_blank"
                download
              >
                {file_name}
              </Box>
            )}
            <Typography color="text.secondary" variant="caption">
              {rowText.length > 0 ? rowText : ''}
            </Typography>
          </Fragment>
        );
      })}
    </>
  );
}

export default LinkAndRowsColumn;
