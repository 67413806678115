import { useState } from 'react';

import EditIcon from '@mui/icons-material/EditOutlined';

import Button from 'shared/ui/button/Button';

import ContractVersionWizard from 'accruals/wizards/contract-version-wizard/ContractVersionWizard';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';

import useContractAndPeriodWithVersions from '../hooks/useContractAndPeriodWithVersions';

function EditContractVersion() {
  const [editContractVersionWizardOpen, setEditContractVersionWizardOpen] =
    useState(false);
  const { contractContainer, contractVersionTraceId } =
    useContractAndPeriodWithVersions();

  return (
    <HasPermission permissions={['canEditTrialInfo']}>
      <Button
        disabled={!contractVersionTraceId}
        size="medium"
        startIcon={<EditIcon />}
        testId="edit"
        variant="outlined"
        onClick={() => setEditContractVersionWizardOpen(true)}
      >
        Edit
      </Button>
      {contractContainer && editContractVersionWizardOpen && (
        <ContractVersionWizard
          contractContainerId={contractContainer.trace_id}
          contractVersionTraceId={contractVersionTraceId}
          onClose={() => setEditContractVersionWizardOpen(false)}
        />
      )}
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(EditContractVersion);
