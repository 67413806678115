import { useState } from 'react';

import useChecklistSectionData from 'accruals/pages/financial-close/accounting-checklist/tasks-status/useChecklistSectionData';
import EdcUploadWizard from 'accruals/wizards/edc-upload-wizard/EdcUploadWizard';
import type { UploadStatusItem } from 'shared/lib/types';

import TrialUploadsListItem from './TrialUploadsListItem';

function EdcScreenFailUploads() {
  const sections = useChecklistSectionData(true);
  const uploadStatus = sections[0]?.tasks?.[3]?.status?.items?.[2];
  const [showUploadScreenFail, setShowUploadScreenFail] =
    useState<boolean>(false);

  return (
    <>
      <TrialUploadsListItem
        primaryText="EDC - screen fail"
        uploadStatus={
          uploadStatus ? (uploadStatus as UploadStatusItem) : undefined
        }
        onUpload={() => setShowUploadScreenFail(true)}
      />
      {showUploadScreenFail && (
        <EdcUploadWizard
          fileUploadType="SFS"
          onClose={() => setShowUploadScreenFail(false)}
          onComplete={() => setShowUploadScreenFail(false)}
        />
      )}
    </>
  );
}

export default EdcScreenFailUploads;
