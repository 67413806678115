import Box from '@mui/material/Box';

import SiteListingUploads from './SiteListingUploads';
import EdcVisitsUploads from './EdcVisitsUploads';
import EdcUnscheduledVisitsUploads from './EdcUnscheduledVisitsUploads';
import EdcScreenFailUploads from './EdcScreenFailUploads';
import EdcProceduresUploads from './EdcProceduresUploads';

function TrialUploadsList() {
  return (
    <Box component="ul" p={0}>
      <SiteListingUploads />
      <EdcVisitsUploads />
      <EdcUnscheduledVisitsUploads />
      <EdcScreenFailUploads />
      <EdcProceduresUploads />
    </Box>
  );
}

export default TrialUploadsList;
