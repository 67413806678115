import { useState } from 'react';

import SiteListingUploadWizard from 'accruals/wizards/csv-upload-wizards/site-listing-upload-wizard/SiteListingUploadWizard';
import useChecklistSectionData from 'accruals/pages/financial-close/accounting-checklist/tasks-status/useChecklistSectionData';
import type { UploadStatusItem } from 'shared/lib/types';

import TrialUploadsListItem from './TrialUploadsListItem';

function SiteListingUploads() {
  const sections = useChecklistSectionData(true);
  const [showSiteListing, setShowSiteListing] = useState<boolean>(false);
  const uploadStatus = sections[0]?.tasks?.[4]?.status?.items?.[0];

  return (
    <>
      <TrialUploadsListItem
        primaryText="Site listing"
        uploadStatus={
          uploadStatus ? (uploadStatus as UploadStatusItem) : undefined
        }
        onUpload={() => setShowSiteListing(true)}
      />
      {showSiteListing && (
        <SiteListingUploadWizard
          onClose={() => setShowSiteListing(false)}
          onComplete={() => setShowSiteListing(false)}
        />
      )}
    </>
  );
}

export default SiteListingUploads;
