import { useState } from 'react';

import useChecklistSectionData from 'accruals/pages/financial-close/accounting-checklist/tasks-status/useChecklistSectionData';
import EdcUploadWizard from 'accruals/wizards/edc-upload-wizard/EdcUploadWizard';
import type { UploadStatusItem } from 'shared/lib/types';

import TrialUploadsListItem from './TrialUploadsListItem';

function EdcVisitsUploads() {
  const sections = useChecklistSectionData(true);
  const uploadStatus = sections[0]?.tasks?.[3]?.status?.items?.[0];
  const [showUploadVisits, setShowUploadVisits] = useState<boolean>(false);

  return (
    <>
      <TrialUploadsListItem
        primaryText="EDC - visits"
        uploadStatus={
          uploadStatus ? (uploadStatus as UploadStatusItem) : undefined
        }
        onUpload={() => setShowUploadVisits(true)}
      />
      {showUploadVisits && (
        <EdcUploadWizard
          fileUploadType="EDCS"
          onClose={() => setShowUploadVisits(false)}
          onComplete={() => setShowUploadVisits(false)}
        />
      )}
    </>
  );
}

export default EdcVisitsUploads;
