import type { ReactElement } from 'react';
import { cloneElement, useState } from 'react';

import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

type Props = {
  children: ReactElement;
  content: ReactElement;
  open: boolean;
};

function TrialUploadsPopper(props: Props) {
  const { open, children, content } = props;
  const [childNode, setChildNode] = useState<HTMLElement | null>(null);

  return (
    <>
      {cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        anchorEl={childNode}
        modifiers={[{ name: 'offset', options: { offset: [0, 15] } }]}
        open={open}
        placement="bottom-end"
        sx={{ zIndex: 1 }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} in={open}>
            <Paper
              elevation={1}
              sx={{
                borderRadius: 2,
                border: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box p={2}>{content}</Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
}

export default TrialUploadsPopper;
