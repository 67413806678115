import type { ICellRendererParams } from '@ag-grid-community/core';
import Link from '@mui/material/Link';

function AgGridFileHyperlinkCellRenderer(params: ICellRendererParams) {
  const { data, value } = params;

  // target and rel are for older browsers who don't honor `download`
  return (
    <Link
      href={data?.file_url}
      rel="noreferrer"
      sx={{ ml: 1 }}
      target="_blank"
      download
    >
      {value}
    </Link>
  );
}

export default AgGridFileHyperlinkCellRenderer;
