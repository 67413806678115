import LockIcon from '@mui/icons-material/Lock';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Tooltip from '@mui/material/Tooltip';

import IconButton, {
  iconButtonClasses,
} from 'shared/ui/icon-button/IconButton';

import useHasPermission from 'shared/lib/permissions/useHasPermission';
import { type LockGrid, LockGridStatus } from 'shared/lib/types';

import withFeatureFlagRequired from 'shared/api/hocs/withFeatureFlagRequired';
import {
  useGetLockGridWithFiltersQuery,
  useUpsertLockGridMutation,
} from 'shared/api/rtkq/lockgrids';

import useLockGridQueryParams from './useLockGridQueryParams';

type Props = {
  grid: LockGrid;
};

function LockGridButton(props: Props) {
  const { grid } = props;
  const params = useLockGridQueryParams(grid);
  const userCanLockGrids = useHasPermission(['canLockGrids']);
  const { currentData } = useGetLockGridWithFiltersQuery(params);
  const [upsertLockGrid] = useUpsertLockGridMutation();

  const locked = currentData?.[0]?.status === LockGridStatus.LOCK_GRID;

  function onClick() {
    void upsertLockGrid({
      ...params,
      status: locked ? LockGridStatus.UNLOCK_GRID : LockGridStatus.LOCK_GRID,
    });
  }

  // <span> is needed as you can't add a tooltip to a disabled button
  return (
    <Tooltip
      placement="top"
      title={
        userCanLockGrids
          ? locked
            ? 'Unlock grid'
            : 'Lock grid'
          : 'You do not have permission to lock or unlock grids'
      }
      arrow
    >
      <span>
        <IconButton
          data-testid={`${grid}_LOCK`}
          disabled={!userCanLockGrids}
          sx={{
            ml: 1,
            [`&.${iconButtonClasses.root}`]: {
              backgroundColor: ({ palette }) => palette.primary.light,
            },
          }}
          onClick={onClick}
        >
          {locked ? (
            <LockIcon color="primary" />
          ) : (
            <LockOpenOutlinedIcon color="primary" />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default withFeatureFlagRequired(LockGridButton, 'lock_grid');
