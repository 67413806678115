import { Fragment } from 'react/jsx-runtime';
import Box from '@mui/material/Box';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import Tooltip from '@mui/material/Tooltip';

import IconButton from 'shared/ui/icon-button/IconButton';

import type { UploadStatusFile } from 'shared/lib/types';

type Props = {
  files: UploadStatusFile[];
};

function FileDownloadColumn(props: Props) {
  const { files } = props;

  return (
    <>
      {files.map(({ file_url, file_name }) => (
        <Fragment key={file_url}>
          {!!file_name && !!file_url && (
            <Tooltip placement="bottom" title="Download" arrow>
              <Box
                component="a"
                href={file_url}
                rel="noreferrer"
                sx={{ display: 'block' }}
                target="_blank"
                download
              >
                <IconButton>
                  <DownloadOutlinedIcon />
                </IconButton>
              </Box>
            </Tooltip>
          )}
        </Fragment>
      ))}
    </>
  );
}

export default FileDownloadColumn;
