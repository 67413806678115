import { useState } from 'react';

import useChecklistSectionData from 'accruals/pages/financial-close/accounting-checklist/tasks-status/useChecklistSectionData';
import EdcUploadWizard from 'accruals/wizards/edc-upload-wizard/EdcUploadWizard';
import type { UploadStatusItem } from 'shared/lib/types';

import TrialUploadsListItem from './TrialUploadsListItem';

function EdcUnscheduledVisitsUploads() {
  const sections = useChecklistSectionData(true);
  const uploadStatus = sections[0]?.tasks?.[3]?.status?.items?.[1];
  const [showUploadUnscheduledVisits, setShowUploadUnscheduledVisits] =
    useState<boolean>(false);

  return (
    <>
      <TrialUploadsListItem
        primaryText="EDC - unscheduled"
        uploadStatus={
          uploadStatus ? (uploadStatus as UploadStatusItem) : undefined
        }
        onUpload={() => setShowUploadUnscheduledVisits(true)}
      />
      {showUploadUnscheduledVisits && (
        <EdcUploadWizard
          fileUploadType="UVS"
          onClose={() => setShowUploadUnscheduledVisits(false)}
          onComplete={() => setShowUploadUnscheduledVisits(false)}
        />
      )}
    </>
  );
}

export default EdcUnscheduledVisitsUploads;
