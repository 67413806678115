import { useState } from 'react';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import IconButton from 'shared/ui/icon-button/IconButton';

import TrialUploadsPopper from './TrialUploadsPopper';
import TrialUploadsContent from './TrialUploadsContent';

function TrialUploads() {
  const [showFrequentUploadsDropdown, setShowFrequentUploadsDropdown] =
    useState(false);

  return (
    <TrialUploadsPopper
      content={<TrialUploadsContent />}
      open={showFrequentUploadsDropdown}
    >
      <span>
        <IconButton
          onClick={() =>
            setShowFrequentUploadsDropdown((prevState) => !prevState)
          }
        >
          <DownloadForOfflineIcon
            sx={{
              transform: 'rotate(180deg)',
              color: ({ palette }) => palette.primary.dark,
            }}
          />
        </IconButton>
      </span>
    </TrialUploadsPopper>
  );
}

export default TrialUploads;
